import React from "react";
import { Image, Modal } from "react-bootstrap";
import styled from "styled-components";
import BgHookPresentSlide from "../../../assets/img/popuphookslide.png";

export default function PopupHookPresentSlide(props) {
  const closePopup = () => {
    props.onHide();
  };
  const onClickPresent = () => {
    props.onClickPresent();
  }

  return (
    <Modal
      {...props}
      size="lg"
      id="modal-success"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade"
      animation="true"
      onHide={props.onHide}
    >
      <ClosePopup onClick={() => closePopup()}>
        <span aria-hidden="true">&times;</span>
      </ClosePopup>
      <Modal.Body>
        <Image style={{
          objectFit: "cover",
          width:"100%",
        }} src={BgHookPresentSlide} onClick={onClickPresent}/>
      </Modal.Body>
    </Modal>
  );
}
const ClosePopup = styled.button`
  position: absolute;
  right: 24px;
  z-index: 99;
  top: 9px;
  span {
    font-size: 40px;
    opacity: 0.6;
  }
`;
