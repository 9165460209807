import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import {
  postUpdateUserInformation,
  postUpdateUserEmailPhone,
} from "edu_lms/services/info/info";
import { APP_ID } from "edu_lms/constants/type";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import * as TYPE from "edu_lms/constants/type";

const today = new Date();

const phoneRegExp = /([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;
const schema = yup
  .object()
  .shape({
    full_name: yup.string().required("Vui lòng nhập đầy đủ họ tên"),
    job_id: yup.object().required("Vui lòng chọn chức vụ"),
    email: yup.string().required("Vui lòng nhập email"),
    phone: yup
      .string()
      .required("Vui lòng nhập số điện thoại")
      .matches(phoneRegExp, "SĐT không hợp lệ"),
  })
  .required();

const Form = styled.form`
  & input {
    height: 36px;
  }
`;

export default function PersonalInformation(props) {
  const dispatch = useDispatch();
  const data = props.dataUser;
  const dataUserInfo = onResultUserInfo();
  const verifiedEmail = dataUserInfo?.email_verified === 1;
  const { register, handleSubmit, setValue, errors, control, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: dataUserInfo?.email,
      phone: dataUserInfo?.phone,
    },
  });
  const formattedToday = today.toISOString().split('T')[0];

  useEffect(() => {
    const newDate = dayjs(data.birth_day * 1000).format("YYYY-MM-DD");
    if (data.full_name) setValue("full_name", data.full_name);
    if (data?.birth_day) setValue("birth_day", newDate);
    if (data?.gender_id)
      setValue("gender_id", getValueOption(TYPE.GENDER, data?.gender_id));
    if (data.job_id)
      setValue("job_id", getValueOption(TYPE.TYPE_LIST_JOB, data.job_id));
  }, [data]);
  const getValueOption = (list, id) => {
    const valueOption = list.filter((item) => item.id === id)[0];
    return valueOption;
  };

  const onHandleSubmit = async (dataForm) => {
    try {
      const userInfo = {
        ...dataForm,
        birth_day: (Date.parse(dataForm?.birth_day) / 1000).toString(),
        gender_id: dataForm.gender_id?.value,
        job_id: dataForm.job_id.id,
        full_name: dataForm.full_name.trim(),
      };
      const contactInfo = {
        email: verifiedEmail
          ? dataUserInfo.email.trim()
          : dataForm.email.trim(),
        phone: dataForm.phone,
        is_web: 1,
        app_id: APP_ID,
      };
      await postUpdateUserInformation(userInfo);
      const res = await postUpdateUserEmailPhone(contactInfo);

      if (res.data.status === "success") {
        props.onUpdateSuccess({ ...dataUserInfo, ...userInfo, ...contactInfo });
        dispatch(
          onDispatchDataInfo({
            full_name: dataForm.full_name,
            phone: dataForm.phone,
            email: dataForm.email,
          })
        );
        toast.success("Cập nhật thông tin thành công!");
        props.onHide();
        const newDataUserInfo = {
          ...dataUserInfo,
          ...userInfo,
          ...contactInfo,
        };
        setItemUserInfo(newDataUserInfo);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
    props.getUserInfoApi(dataForm);
  };

  const onReset = () => {
    const newDate = dayjs(dataUserInfo?.birth_day * 1000).format("YYYY-MM-DD");
    reset({
      full_name: dataUserInfo?.full_name,
      birth_day: newDate,
      gender_id: getValueOption(TYPE.GENDER, dataUserInfo?.gender_id),
      job_id: getValueOption(TYPE.TYPE_LIST_JOB, dataUserInfo?.job_id),
      email: dataUserInfo?.email,
      phone: dataUserInfo?.phone,
    });
  };
  // const backClassInfor = () => {
  //   props.setShowPersonal(3);
  // };

  return (
    <>
      <div
        id="user"
        className="tab-pane fade show active form-h10"
        role="tabpanel"
      >
        <BackClass>
          <h2 className="title">Cá nhân</h2>
          <p className=" pb-3 text-left monkey-fz-20 monkey-color-orange">
            {props?.title ? props?.title : 'Kết quả tập huấn sẽ được gửi về Sở và EMAIL GIÁO VIÊN! Thầy cô vui\n' +
                '                  lòng cập nhật thông tin chính xác và đầy đủ.'}
          </p>
        </BackClass>
        <Form onSubmit={handleSubmit(onHandleSubmit)}>
          <div className="form-row">
            <div className="form-group">
              <label>
                Họ và tên <sup>*</sup>
              </label>
              <input
                type="text"
                placeholder="Nhập họ và tên"
                name="full_name"
                ref={register}
                className="input-update"
              />
              <p className="text-danger">{errors.full_name?.message}</p>
            </div>
            <div className="form-group">
              <label>Ngày tháng năm sinh</label>
              <input
                type="date"
                max={formattedToday}
                placeholder="Nhập ngày tháng năm sinh *"
                name="birth_day"
                ref={register}
                className="input-update"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Giới tính</label>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    placeholder="Vui lòng chọn giới tính"
                    options={TYPE.GENDER}
                    maxMenuHeight={200}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
                name="gender_id"
                control={control}
              />
            </div>
            <div className="form-group">
              <label>
                Chức vụ <sup>*</sup>
              </label>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    placeholder="Vui lòng chọn chức vụ"
                    options={TYPE.TYPE_LIST_JOB}
                    maxMenuHeight={200}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
                name="job_id"
                control={control}
              />
              <p className="text-danger">{errors.job_id?.message}</p>
            </div>
          </div>
          <div className="form-row">
            <WrapperInputEmail
              className={classNames("form-group", {
                pointer: verifiedEmail,
              })}
            >
              <label>
                Email <sup>*</sup>
              </label>
              <input
                type="email"
                placeholder="Nhập email"
                name="email"
                ref={register}
                className="input-update"
              />
              <p className="text-danger">{errors.email?.message}</p>
            </WrapperInputEmail>
            <div className="form-group">
              <label>
                Số điện thoại <sup>*</sup>
              </label>
              <input
                type="tel"
                placeholder="Nhập số điện thoại"
                name="phone"
                ref={register}
                className="input-update"
              />
              <p className="text-danger">{errors.phone?.message}</p>
            </div>
          </div>
          <ButtonSubmit className="box-btn box-btn-fw flex mt-14">
            <button
              type="button"
              className="btn-reset flex-center txt-4"
              onClick={() => onReset()}
            >
              Hủy
            </button>
            <button type="submit" className="btn-submit flex-center btn-pr">
              Gửi
            </button>
          </ButtonSubmit>
        </Form>
      </div>
    </>
  );
}
const BackClass = styled.div`
  justify-content: space-between;
  button {
    height: 48px;
    padding: 0 5px;
  }
`;
const ButtonSubmit = styled.div`
  justify-content: space-between;
  button {
    width: 120px !important;
    height: 48px;
    border-radius: 6px;
  }
`;
const WrapperInputEmail = styled.div`
  &.pointer {
    pointer-events: none;
  }
`;
