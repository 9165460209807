import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import React from "react";

export default function PopupVerificationEmail({
  show,
  onHide,
  userInfo,
  seconds,
  minutes,
  onSubmit,
    title
}) {


  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
        onHide={onHide}
      >
        <Modal.Header closeButton className="">
          <Modal.Title
            className="modal-title quicksand-bold w-100 text-center"
            style={{ color: "#2A404F" }}
          >
            <p className="d-flex justify-content-center">
              {title ? (
                  <>
                    <Span>{title}</Span>
                  </>
              ) : (
                  <>
                    Xác thực email của thầy/cô
                  </>
              )}
              
             
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="p-4 monkey-fz-20 text-center">
            <p className="">
              {!title && (
                  <>
                    Kết quả tập huấn của thầy/cô sẽ được gửi về email:{" "}
                    <Span>{userInfo?.email}</Span>
                  </>
              )}
           
            </p>
            <p className="pt-2">
              Thầy/cô vui lòng ấn vào nút <Span>"Gửi mã xác thực"</Span> sau đó
              kiểm tra hòm thư và làm theo hướng dẫn để hoàn tất bước
              xác thực. <br/>Nếu không tìm thấy mã, hãy kiểm tra{" "}
              <Span>hòm thư Spam.</Span>
            </p>
          </div>

          <div className="d-flex justify-content-center mt-3"></div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center monkey-fz-20">
          <Button
            type="submit"
            className="btn btn-pr ml-4"
            disabled={!(minutes === 0 && seconds === 0)}
            onClick={() => onSubmit()}
          >
              Gửi mã xác thực
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Button = styled.button`
  min-width: 170px;
  height: 42px;
`;

const Span = styled.span`
  color: #2a404f;
  font-weight: 600;
`;
