import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Present from "./components/Present";
import { getDetailSlideLibrary } from "../../../edu_lms/services/slide";
import Header from "./components/Header";
import { decryptBase64 } from "edu_lms/modules/selection";
import SEO from "edu_lms/components/Seo";
import { getMetaData } from "../SlideLibrary/constant";
import { removeAccents } from "edu_lms/components/selection";
import { ROUTE_PATH_NOT_FOUND, ROUTE_PATH_TU_SACH, ROUTE_PATH_V3_LIST_SLIDE } from "consts/routePaths";
import {postSaveReportErrorLesson} from "../../../edu_lms/services/reportError";
import FormReportError from "../../../edu_lms/modules/ReportError/components/FormReportError";
import {LIST_PROBLEM_LESSON} from "../../../edu_lms/modules/ReadingBooks/components/const";
import PopUpReportSuccess from "../../../edu_lms/modules/ReportError/components/PopUpReportSuccess";
import PopupHookPresentSlide from "./components/PopupHookPresentSlide";
import { useBookStore } from "stores/bookStore";

export default function ReadingSlide(props) {
  const { subject, grade, title, id } = useParams();
  const history = useHistory();
  let location = useLocation();
  const slideId = decryptBase64(props.match.params.id);

  const [showPresentModal, setStateShowPresentModal] = useState(false);
  const [isGetSlideInfo, setIsGetSlideInfo] = useState(false);
  const [showFormReportErrorModal, setShowFormReportErrorModal] = useState(false);
  const [showPopUpReportSuccessModal, setShowPopUpReportSuccessModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPresentModalHook, setShowPresentModalHook] = useState(true);
  const [isLoadingSlideInfo, setIsLoadingSlideInfo] = useState(false)

  const setDocs = useBookStore((state) => state.setDocs);

  const [slideInfo, setSlideInfo] = useState({
    dataSlide: [],
    activeIndexSlide: 0,
    title: "",
    subjectName: "",
    gradeName: "",
    slug: "",
  });

  useEffect(() => {
    if (slideId) {
	  // const userInfo = onResultUserInfo();
      const data = {
        slide_id: slideId,
	    // email: userInfo?.email
      };
      onGetDetailSlide(data);
    }
  }, [slideId]);

  useEffect(() => {
    if (isGetSlideInfo) {

      const isMatchUrl =
        removeAccents(slideInfo.gradeName, 2) === removeAccents(grade, 2) &&
        removeAccents(slideInfo.subjectName, 2) === removeAccents(subject, 2);
   
      if (!isMatchUrl) {
        history.push(ROUTE_PATH_NOT_FOUND);
      } else {
        history.replace(
          `${ROUTE_PATH_V3_LIST_SLIDE}${removeAccents(
            slideInfo.subjectName,
            2
          )}/${removeAccents(slideInfo.gradeName, 2)}/${removeAccents(
            slideInfo.slug,
            2
          )}/${id}/`
        );
      }
    }
  }, [slideInfo, isGetSlideInfo]);

  const onGetDetailSlide = (data) => {
    setIsLoadingSlideInfo(true);
    getDetailSlideLibrary(data)
      .then((res) => {
        setIsLoadingSlideInfo(false);
        if (res.data.status === "success") {
          const slideData = res.data.data;
		  if (slideData?.is_user_hoc_10 === 0){
			  if (slideData?.is_week_current === 0){
				  return history.push('/bai-giang')
			  }
		  }
          setSlideInfo((prevSlideInfo) => ({
            ...prevSlideInfo,
            dataSlide: slideData.slide_images,
            title: slideData.title,
            subjectName: slideData?.subject.title,
            gradeName: slideData?.grade.name,
            url: slideData.url,
            slug: slideData.slug,
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingSlideInfo(false);
        setIsGetSlideInfo(true);
      });
  };

  const saveReportError = (data) => {
    const postData = {
      slide_index: slideInfo.activeIndexSlide + 1,
      subject: slideInfo.subjectName,
      title: slideInfo.title,
      grade: slideInfo.gradeName,
      data: data,
    };
    console.log(postData);
    if (
        data.content ||
        data.effect ||
        data.other_problems ||
        data.input_other_problems !== ""
    ) {
      postSaveReportErrorLesson(postData).catch((errors) => {
        console.log(errors);
      });
      setShowFormReportErrorModal(false);
      setShowPopUpReportSuccessModal(true);
      setIsSubmit(true);
    }
  };

  const onShowPopupPresent = () => {
    const uri = `${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${slideInfo.url}`;
    setDocs([{ uri,title: slideInfo.title }]);
    history.push(`${ROUTE_PATH_TU_SACH}doc-view-page?back_url=${location.pathname}`);
  };
  const onShowPopupError = useCallback(() => {
    setShowFormReportErrorModal(true);
  }, []);
  
  const handleClose = useCallback(() => {
    setStateShowPresentModal(false);
    setShowFormReportErrorModal(false);
    setShowPopUpReportSuccessModal(false);
    setIsSubmit(false);
  }, []);

  const changeSlide = useCallback((index) => {
    setSlideInfo((prevSlideInfo) => ({
      ...prevSlideInfo,
      activeIndexSlide: index,
    }));
  }, []);

  const titleSubjectAndGrade = `${slideInfo.subjectName} ${slideInfo.gradeName} ${slideInfo.title}`

  const metaData = getMetaData(
    `Giáo án bài giảng điện tử môn ${titleSubjectAndGrade}`,
    `Giáo án - bài giảng điện tử môn ${titleSubjectAndGrade} bộ sách Cánh Diều được xây dựng bởi đội ngũ giáo viên giỏi, giàu kinh nghiệm.`,
    window.location.href
  );

  return (
    <>
      <SEO data={metaData} />
      <div className="hoc10-slideshow">
        <Header
          title={slideInfo.title}
          slideId={slideId}
          onShowPopupPresent={onShowPopupPresent}
          onShowPopupError={onShowPopupError}
          isLoadingSlideInfo={isLoadingSlideInfo}
        />

        {showPresentModal && (
          <Present
            show={showPresentModal}
            activeIndexSlide={slideInfo.activeIndexSlide}
            onHide={handleClose}
            dataSlide={slideInfo.dataSlide}
            handleClose={handleClose}
          />
        )}

        {showFormReportErrorModal && (
            <FormReportError
                saveReportError={saveReportError}
                show={showFormReportErrorModal}
                onHide={handleClose}
                listProblems={LIST_PROBLEM_LESSON}
            />
        )}

        {showPopUpReportSuccessModal && (
            <PopUpReportSuccess
                show={showPopUpReportSuccessModal}
                onHide={handleClose}
            />
        )}

        <div className="main flex">
          <div className="sidebar">
            <ul className="list-slide">
              {slideInfo.dataSlide.map((slide, index) => (
                <li
                  key={index}
                  className={
                    slideInfo.activeIndexSlide === index ? "active" : ""
                  }
                  onClick={() => changeSlide(index)}
                >
                  <div className="number flex-center txt-4">{index + 1}</div>
                  <div className="img-wrapper">
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL_APP}${slide?.background}`}
                      alt={`Ảnh slide ${index + 1}`}
                      loading="lazy"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <button type="button" className="btn-hide btn-pr flex-center">
              Danh sách
            </button>
          </div>
          <div className="content">
            <div className="slide-wrapper mw-100">
              <img
                src={`${process.env.REACT_APP_MEDIA_URL_APP}${slideInfo.dataSlide[slideInfo.activeIndexSlide]?.background}`}
                alt={`Slide số ${slideInfo.activeIndexSlide + 1}`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      {
        showPresentModalHook && !isLoadingSlideInfo && (
          <PopupHookPresentSlide
            show={showPresentModalHook}
            onHide={() => setShowPresentModalHook(false)}
            onClickPresent={onShowPopupPresent}
          />
        )
      }
    </>
  );
}
