import { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { removeAccents } from "edu_lms/components/selection";
import { ROUTE_PATH_READING_BOOKS, ROUTE_PATH_TU_SACH } from "consts/routePaths";
import CheckModal from "./CheckModalActiveBook";
import { onDispatchBookInfo } from "edu_lms/modules/General/actions";
import { grades, listSubject, bookTypes } from "edu_lms/constants/type";
import { BOOK_INTRO_TAG, getRecentBookInfo, handleModeView } from "./const";
import PopupActiveInstruction from "./Document/PopupActiveInstruction";
import DocsViewComponent from "./DocsViewComponent";
import { onDispatchUpdateReadingBookData } from "edu_lms/modules/ReadingBooks/actions";
import { READING_BOOK_ENTRY_SOURCE } from "edu_lms/constants/book";
import {
	BOOK_TYPE_SACH_GIOI_THIEU,
	BOOK_TYPE_SBT,
	BOOK_TYPE_SGK,
	BOOK_TYPE_SGV, DOCUMENT_CATEGORY_BAI_GIANG_POWER_POINT, DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY,
	DOCUMENT_CATEGORY_TAI_LIEU_BO_TRO,
	DOCUMENT_CATEGORY_TAI_LIEU_THAM_KHAO,
	
} from "consts/book";
import { useBookStore } from "stores/bookStore";

export default function ResultBook({
  data,
  bookTypeId,
  categoryBook,
  col,
  background,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [bookName, setBookName] = useState("");
  const [isRequireLogin, setIsRequireLogin] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [modalLoginSuccess, setModalLoginSuccess] = useState(false);
  const [isActiveModalBook, setIsActiveModalBook] = useState(false);
  const [activeBookSuccess, setActiveBookSuccess] = useState(false);
  const [showPopupWatchNow, setShowPopupWatchNow] = useState(false);
  const [infoWorksheet, setInfoWorksheet] = useState({});
  const [typeBook, setTypeBook] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  const setDocs = useBookStore((state) => state.setDocs);

  const onRequireLogin = () => {
    setIsRequireLogin(false);
    setLoginModalShow(true);
  };
  const onLoginSuccess = () => {
    setLoginModalShow(false);
    setModalLoginSuccess(true);
  };

  const onActiveBookSuccess = (book) => {
    setIsActiveModalBook(false);
    setActiveBookSuccess(true);
    setTypeBook(book);
  };

  const onNextBookDetail = (title, id, typeBook, image, gradeId, subjectId) => {
    let pageId = 0;
    const converTitle = removeAccents(title);
    const url = `${ROUTE_PATH_READING_BOOKS}/${converTitle}/${typeBook}/${id}/${pageId}/`;
    getRecentBookInfo({
      title,
      name: converTitle,
      typeBook,
      id,
      pageId,
      image,
      gradeId,
      subjectId,
    });

    localStorage.setItem("gradeIdOfBook", gradeId);
    dispatch(
      onDispatchUpdateReadingBookData({
        entrySource: READING_BOOK_ENTRY_SOURCE.LIST_BOOK,
      })
    );
    history.push(url);
  };

  const getInfoBook = (title, bookTypeId, bookGradeId, bookSubjectId) => {
    const bookType = bookTypes.find(
      (itemBookType) => itemBookType.id === bookTypeId
    );
    const bookGrade = grades.find(
      (itemGrade) => itemGrade.value === bookGradeId
    );
    const bookSubject = listSubject.find(
      (itemSubject) => itemSubject.id === bookSubjectId
    );

    dispatch(
      onDispatchBookInfo({
        bookName: title,
        bookType: bookType?.title,
        bookGrade: bookGrade?.label,
        bookSubject: bookSubject?.title,
        timeStart: Math.round(Date.now() / 1000),
        count_click_cursor: 0,
        count_click_pencil: 0,
        count_click_brush: 0,
        count_add_text: 0,
        count_add_hyperlink: 0,
        count_add_line: 0,
        count_add_rectangle: 0,
        count_add_circle: 0,
        count_click_delete: 0,
        count_click_delete_all: 0,
        count_select_color: 0,
        count_zoom_in: 0,
        count_zoom_out: 0,
        count_zoom_back_100: 0,
        count_change_page: 0,
        count_show_tools: 0,
        count_open_book_instructions: 0,
        count_open_table_of_contents: 0,
      })
    );
  };

  const nameSupplementDocs = (typeBookId) => {
    if (typeBookId === undefined) {
      return "";
    }
    switch (typeBookId) {
      case 1:
        return " - Sách giáo khoa";
      case 3:
        return " - Sách giáo viên";
      case 5:
        return " - Vở bài tập";
      default:
        break;
    }
  };

  const handleDownload = (document) => {
    if (document.is_active) {
      window.open(document.url);
      return;
    }

    setBookName(document.book_title);
    setShowPopup(true);
  };

  const handleWatchNow = (worksheet) => {
    const uri = `${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${worksheet.is_url}`;

    setInfoWorksheet({ uri, title: worksheet.worksheet_title });
    setDocs([{ uri,title: worksheet.worksheet_title }]);
    if (uri.includes(".pptx")) {
      history.push(`${ROUTE_PATH_TU_SACH}doc-view-page`);
      return
    }
    setShowPopupWatchNow(true);
  };

  return (
    data.length > 0 && (
      <div
        className="result__list"
        style={{
          backgroundColor: `${background}`,
          boxShadow: "5px 5px 10px 1px #ccc",
          padding: `10px ${ [
              BOOK_TYPE_SGK,
              BOOK_TYPE_SGV,
              BOOK_TYPE_SBT,
              BOOK_TYPE_SACH_GIOI_THIEU,
          ].includes(bookTypeId) ? data.length * 10 : 20 }px`,
          marginBottom: "20px",
          borderRadius: "8px",
        }}
      >
        <H4>
          <BookMark className="fa fa-bookmark" aria-hidden="true"></BookMark>
          {categoryBook}
        </H4>
        <Hr />
        <div className="row ">
          {data.map((item, index) => {
            const imgUrl = `${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${item.thumb}`;

            return (
              <div key={index} className={col}>
                {[
                  BOOK_TYPE_SGK,
                  BOOK_TYPE_SGV,
                  BOOK_TYPE_SBT,
                  BOOK_TYPE_SACH_GIOI_THIEU,
                ].includes(bookTypeId) && (
                  <article
                    className="post bg-sh pr"
                    style={{ boxShadow: "5px 5px 10px 1px #ccc" }}
                  >
                    <BookBlocker>
                      <div className="post__thumb cursor">
                        <img
                          onClick={() => {
                            onNextBookDetail(
                              item.title,
                              item.id,
                              item.book_type_id,
                              imgUrl,
                              item.grade_id,
                              item.subject_id
                            );
                            getInfoBook(
                              item.title,
                              item.book_type_id,
                              item.grade_id,
                              item.subject_id
                            );
                          }}
                          src={imgUrl}
                          alt="Các loại sách"
                        />
                      </div>
                    </BookBlocker>
                    <div
                      className="post__content d-flex justify-content-around"
                      style={{ padding: "16px 10px" }}
                    >
                      {[8, 9, 10, 11].includes(item.book_type_id) && (
                        <Semester className="pt-0 align-items-center d-flex">
                          <div className="text-initial">
                            {BOOK_INTRO_TAG[item.book_type_id]}
                          </div>
                        </Semester>
                      )}
                      {item.semester ? (
                        <Semester>{`Tập ${item.semester}`}</Semester>
                      ) : (
                        <Semester>
                          <br />
                        </Semester>
                      )}
                      <div
                        onClick={() => {
                          onNextBookDetail(
                            item.title,
                            item.id,
                            item.book_type_id,
                            imgUrl,
                            item.grade_id,
                            item.subject_id
                          );
                          getInfoBook(
                            item.title,
                            item.book_type_id,
                            item.grade_id,
                            item.subject_id
                          );
                        }}
                        title=""
                        className={classNames(
                          "btn-read btn-sub flex-center cursor position-static m-0",
                          {
                            "btn-watch": !item.is_active,
                          }
                        )}
                      >
                        {item.is_active ? "Xem ngay" : "Đọc thử"}
                      </div>
                    </div>
                  </article>
                )}
                {(bookTypeId === DOCUMENT_CATEGORY_TAI_LIEU_BO_TRO
	                || bookTypeId === DOCUMENT_CATEGORY_TAI_LIEU_THAM_KHAO
	                || bookTypeId === DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY
	                || bookTypeId === DOCUMENT_CATEGORY_BAI_GIANG_POWER_POINT
                ) && (
                  <article
                    className={classNames("post bg-sh position-relative h-207", {
                      "h-207": data.some((_i) =>
                        handleModeView(_i.type_document)
                      )
                    })}
                    style={{ boxShadow: "5px 5px 10px 1px #ccc", height: "207px !important" }}
                  >
                    <DocumentStyle
                      isActive={item.is_active}
                      className="position-relative"
                    >
                      <div
                        className="post__thumb cursor"
                        onClick={() =>
                          handleModeView(item.type_document)
                            ? handleWatchNow(item)
                            : handleDownload(item)
                        }
                      >
                        <img style={{margin: "auto", width: "60%"}} src={item.thumb} alt="Sách" />
                      </div>
                      <div className="position-absolute w-100 h-100 justify-content-center align-items-center lock-overlay">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                      </div>
                    </DocumentStyle>
                    <div
                      className={classNames(
                        "post__content d-flex justify-content-between position-absolute w-100",
                        {
                          "flex-column": handleModeView(item.type_document),
                        }
                      )}
                      style={{
                        padding: "16px 10px",
                        bottom: 0,
                        flex: "1",
                      }}
                    >
                      <Semester
                        title={`${item.worksheet_title} ${
                          item.book_type_id != undefined
                            ? nameSupplementDocs(item.book_type_id)
                            : ""
                        }`}
                        className="mb-0"
                      >{`${item.worksheet_title} ${
                        item.book_type_id != undefined
                          ? nameSupplementDocs(item.book_type_id)
                          : ""
                      }`}</Semester>
                      <div className="d-flex justify-content-between">
                        {handleModeView(item.type_document) ? (
                          <WatchNow
                            className="btn-sub px-2 py-0"
                            onClick={() => handleWatchNow(item)}
                          >
                            Xem ngay
                          </WatchNow>
                        ) : (
                          ""
                        )}
                        <Download
                          className="cursor"
                          onClick={() => handleDownload(item)}
                        >
                          <i
                            className={`fa ${
                              item.is_active ? "fa-download" : "fa-lock"
                            }`}
                            aria-hidden="true"
                          ></i>
                        </Download>
                      </div>
                    </div>
                  </article>
                )}
              </div>
            );
          })}
        </div>
        <DocsViewComponent
          show={showPopupWatchNow}
          setShow={setShowPopupWatchNow}
          infoWorksheet={infoWorksheet}
        />
        <PopupActiveInstruction
          show={showPopup}
          setShow={setShowPopup}
          bookName={bookName}
        />
        <CheckModal
          typeBook={typeBook}
          isRequireLogin={isRequireLogin}
          loginModalShow={loginModalShow}
          modalLoginSuccess={modalLoginSuccess}
          isActiveModalBook={isActiveModalBook}
          activeBookSuccess={activeBookSuccess}
          setIsRequireLogin={setIsRequireLogin}
          setLoginModalShow={setLoginModalShow}
          onRequireLogin={onRequireLogin}
          onLoginSuccess={onLoginSuccess}
          onActiveBookSuccess={onActiveBookSuccess}
          setModalLoginSuccess={setModalLoginSuccess}
          setIsActiveModalBook={setIsActiveModalBook}
          setActiveBookSuccess={setActiveBookSuccess}
        />
      </div>
    )
  );
}

const BookBlocker = styled.div`
  .block {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    border-radius: 12px 12px 0 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    .label-active {
      line-height: 20px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      padding: 0 15px;
    }
  }

  &:hover .block {
    display: flex;
  }
`;

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;

const H4 = styled.h4``;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
`;

const Semester = styled.h4`
  .text-initial {
    text-transform: initial;
  }
`;

const Download = styled.p`
  i {
    padding: 8px 10px;
    border: 1px solid #ff7707;
    border-radius: 5px;
    color: #ff7707;
    &:hover {
      background-color: #ff7707;
      color: #fff;
    }
  }
`;

const WatchNow = styled.button`
  font-size: 12px;
  border: 1px solid #ff7707;
  background-color: #fff;
  color: #ff7707;
  &:hover {
    background-color: #ff7707;
    color: #fff;
  }
`;

const DocumentStyle = styled.div`
  border-bottom: 1px solid #ff7707;

  .lock-overlay {
    display: none;
    top: 0;
    left: 0;
    z-index: 3;
    border-radius: 12px 12px 0 0;
    background-color: rgba(0, 0, 0, 0.5);

    i {
      font-size: 48px;
      color: #ccc;
    }
  }

  ${(props) =>
    !props.isActive &&
    `&:hover .lock-overlay {
      display: flex;
      pointer-events: none;
    }`}
`;
