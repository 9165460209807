import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useBookStore } from "stores/bookStore";
import styled from "styled-components";
import BackButton from "../../edu_lms_v2/assets/img/close.svg";
import { useHistory, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  .header-slide {
    position: fixed;
    width: 100%;
    height: 37px;
    z-index: 99999;
    background-color: white;
  }
`;

const H1 = styled.h1`
  color: #444444;
  font-size: 16px;
  font-weight: 700;
`;
const WrapperDocView = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: -24px;
  top: 37px;
  #proxy-renderer {
    overflow-y: hidden;
  }
  &::after {
    content: "";
    position: absolute;
    width: 84px;
    height: 21px;
    right: 2px;
    bottom: 2px;
    background-color: #444444;
  }
`;

export default function PresentSlide() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const backUrl = searchParams.get("back_url");
  const docs = useBookStore((state) => state.docs);
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  return (
    <Wrapper>
        <div className="py-2 px-1 d-flex align-items-center justify-content-between header-slide">
          <H1>Tên bài giảng: {docs[0].title}</H1>
          <Button variant="warning" onClick={() => {
              if (backUrl) {
                history.push(backUrl)  
              } else {
                history.push("/tu-sach")
              }
            }
          }>
            <Image src={BackButton} width={14} />
          </Button>
        </div>
      <WrapperDocView>
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true,
            },
          }}
          className="remove-scroll-docs-view rounded"
          style={{ height: "100%" }}
        />
      </WrapperDocView>
    </Wrapper>
  );
}
