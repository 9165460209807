import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import UpdateInformationTraining from "./index";

export default function ModalUpdateInformation(props) {
  const closePopup = () => {
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      id="modal-success"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade"
      animation="true"
      onHide={props.onHide}
    >
      <ClosePopup onClick={() => closePopup()}>
        <span aria-hidden="true">&times;</span>
      </ClosePopup>

      <div className="text-center">
        <UpdateInformationTraining
          checkUpdateInfor={props?.checkUpdateInfor}
          urlSubjectId={props?.urlSubjectId}
          urlGradeId={props?.urlGradeId}
          setShowUpdateInfor={props?.setShowUpdateInfor}
          onGetDataHistory={props.onGetDataHistory}
          infoRequired={props.infoRequired}
          onUpdateSuccess={props.onUpdateSuccess}
          getUserInfoApi={props.getUserInfoApi}
          onHide={props.onHide}
          checkInformation={props?.checkInformation}
          title={props?.title}
        />
      </div>
    </Modal>
  );
}
const ClosePopup = styled.button`
  position: absolute;
  right: 50px;
  z-index: 99;
  span {
    font-size: 40px;
    opacity: 0.6;
  }
`;
