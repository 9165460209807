import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "reactstrap";
import styled from "styled-components";
import classNames from "classnames";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ROUTE_PATH_V3_LIST_SLIDE } from "consts/routePaths";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  postDownloadSlideLibrary,
  postUpdateSlideLibrary,
} from "edu_lms/services/slide";
import { useAuthStore } from "../../../../stores/authStore";
import * as TYPE from "../../../../edu_lms/constants/type";
import { onResultUserInfo } from "../../../../edu_lms/modules/selection";
import PopupVerificationEmail from "../../Training/TrainingTest/UpdateInformation/components/PopupVerificationEmail";
import { sendVerifiedOtp } from "../../../../edu_lms/services/training";
import PopupResendEmail from "../../Training/TrainingTest/UpdateInformation/components/PopupResendEmail";
import ModalUpdateInformation from "../../Training/TrainingTest/UpdateInformation/ModalUpdateInformation";

export default function Header(props) {
  const history = useHistory();
  const { subject, grade } = useParams();

  const [tooltipPresent, setTooltipPresent] = useState(false);
  const togglePresent = () => setTooltipPresent(!tooltipPresent);
  const [tooltipError, setTooltipError] = useState(false);
  const toggleError = () => setTooltipError(!tooltipError);
  const [tooltipDownload, setTooltipDownload] = useState(false);
  const toggleDownload = () => setTooltipDownload(!tooltipDownload);
  const [showLoadingDownload, setStateShowLoadingDownload] = useState(false);
  const [isActiveToggle, setActiveToggle] = useState(false);
  const openLoginModal = useAuthStore((state) => state.openLoginModal);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const fullCurrentUrl = window.location.href;
  const [userInfo, setUserInfo] = useState(onResultUserInfo());
  const verifiedEmail = userInfo?.email_verified === TYPE.EMAIL_VERIFIED;
  const [showPopupVerifyEmail, setShowPopupVerifyEmail] = useState(false);
  const token = localStorage.getItem("token");
  const [showPopupResendEmail, setShowPopupResendEmail] = useState(false);
  const timeSendEmail = JSON.parse(localStorage.getItem("timeSendEmail"));
  const [seconds, setSeconds] = useState(timeSendEmail?.seconds ?? 0);
  const [minutes, setMinutes] = useState(timeSendEmail?.minutes ?? 0);
  const [isSendOTPSuccess, setSendOTPSuccess] = useState(false);
  const [showUpdateInfor, setShowUpdateInfor] = useState(false);
  const [checkUpdateInfor, setCheckUpdateInfor] = useState("");
  const [checkInformation, setCheckInformation] = useState(0);
  
  const onUpdateSuccess = (data) => {
    if(!userInfo?.email) {
      setUserInfo((userInfo) => ({ ...userInfo, ...data }));
      setShowPopupVerifyEmail(true)
    } else {
      setUserInfo((userInfo) => ({ ...userInfo, ...data }));
    }
  };
  const handleBackPageListSlide = () => {
    history.push(`${ROUTE_PATH_V3_LIST_SLIDE}${subject}/${grade}/`)
  }
  const toggleToggle = () => {
    setActiveToggle(!isActiveToggle);
  };

  const showPopupPresent = () => {
    props.onShowPopupPresent();
  };

  const showPopupError = () => {
    props.onShowPopupError();
  }
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    isSendOTPSuccess
        ? localStorage.setItem(
            "timeSendEmail",
            JSON.stringify({ seconds: 0, minutes: 0 })
        )
        : localStorage.setItem(
            "timeSendEmail",
            JSON.stringify({ seconds: seconds, minutes: minutes })
        );
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const downloadFile = () => {
    if (!isLoggedIn) {
      // setShowPopupContent(true);
      // setTargetRoute(url);
      openLoginModal();
      return;
    }
    
    if(!userInfo?.email) {
      setShowUpdateInfor(true)
      return true;
    }
  
    if(!verifiedEmail) {
      setShowPopupVerifyEmail(
          timeSendEmail?.seconds === 0 && timeSendEmail?.minutes === 0
      );
      setShowPopupResendEmail(
          timeSendEmail?.seconds !== 0 || timeSendEmail?.minutes !== 0
      );
      return;
    }
    
    setStateShowLoadingDownload(true);
    const data = {
      slideId: props.slideId,
      typeUpdate: 3,
    };
    postDownloadSlideLibrary(data)
      .then((res) => {
        if (res.data.status === "success") {
          const a = document.createElement("a");
          a.href = res.data.data.url;
          a.download = res.data.data.url.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          toast.success("Tải xuống bài giảng thành công");

          postUpdateSlideLibrary(data)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.data.message);
        }
        setStateShowLoadingDownload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyLink = () => {
    const data = {
      slideId: props.slideId,
      typeUpdate: 4,
    };
    postUpdateSlideLibrary(data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
    toast.success("Copy link thành công!");
  };

  const share = () => {
    const data = {
      slideId: props.slideId,
      typeUpdate: 2,
    };
    postUpdateSlideLibrary(data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = () => {
    const data = {
      token: token,
    };
    sendVerifiedOtp(data).then((res) => {
      if (res.data.status === "success") {
        setShowPopupVerifyEmail(false);
        setShowPopupResendEmail(true);
        setSeconds(59);
        setMinutes(4);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    });
  };
  return (
    <div className="header">
      <div className="flex-lc h-100 ml-3 mr-5">
        <div title="" className="btn-back flex-lc">
          <i
            onClick={() => handleBackPageListSlide()}
            className="icon icon-back cursor"
          ></i>
          <h1 className="text">{props.title}</h1>
        </div>
        <div className="box-btn flex-lc">
          <i
              id="tooltip-error"
              onClick={() => showPopupError()}
              className="cursor fa fa-exclamation-triangle"
              style={{
                marginRight: "9px",
                fontSize: "1.4em",
              }}
          />
          <Tooltip
              isOpen={tooltipError}
              target="tooltip-error"
              toggle={toggleError}
              placement="top"
          >
            Báo lỗi
          </Tooltip>
          {
            !props.isLoadingSlideInfo && <>
            <button
              type="button"
              className="btn-play flex-center"
              onClick={showPopupPresent}
              id="tooltip-present"
          >
            <i className="icon icon-play"></i> Trình chiếu
          </button>
          <Tooltip
              isOpen={tooltipPresent}
              target="tooltip-present"
              toggle={togglePresent}
          >
            Trình chiếu bản xem nhanh
          </Tooltip>
            </>
          }
          <button
              onClick={downloadFile}
              type="button"
              className="btn-download flex-center"
              id="tooltip-download"
          >
            {showLoadingDownload ? (
                <div className="loader"></div>
            ) : (
                <>
                  <i className="icon icon-download" aria-hidden="true"></i> Tải
                                                                            xuống
                </>
            )}
          </button>
          {" "}
          <Tooltip
              isOpen={tooltipDownload}
              target="tooltip-download"
              toggle={toggleDownload}
          >
            Tải xuống bản đầy đủ
          </Tooltip>
          <ModalUpdateInformation
              show={showUpdateInfor}
              checkUpdateInfor={checkUpdateInfor}
              setShowUpdateInfor={setShowUpdateInfor}
              onHide={() => setShowUpdateInfor(false)}
              onUpdateSuccess={onUpdateSuccess}
              setCheckInformation={setCheckInformation}
              checkInformation={checkInformation}
              title={'Vui lòng cập nhật thông tin email để nhận mã xác thực'}
          />
          <PopupVerificationEmail
              show={showPopupVerifyEmail}
              onHide={() => setShowPopupVerifyEmail(false)}
              userInfo={userInfo}
              seconds={seconds}
              minutes={minutes}
              title={ "Xác thực email để tải bài giảng!"}
              onSubmit={onSubmit}
          />
          <PopupResendEmail
              show={showPopupResendEmail}
              onHide={() => setShowPopupResendEmail(false)}
              userInfo={userInfo}
              onSubmit={onSubmit}
              seconds={seconds}
              setSeconds={setSeconds}
              minutes={minutes}
              setMinutes={setMinutes}
              onUpdateSuccess={onUpdateSuccess}
              setSendOTPSuccess={setSendOTPSuccess}
          />
          <button
              type="button"
              className="btn-share flex-center"
              id="btnShare"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            <i className="icon icon-share"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="share">
            <p className="dropdown-item" onClick={(e) => share()}>
              <FacebookShareButton
                  url={fullCurrentUrl}
                  quote={props.title}
                  hashtag={"#hoc10.vn"}
                  description={props.title}
                  className="hoc10"
              >
                <FacebookIcon size={32} round/> Facebook
              </FacebookShareButton>
            </p>
            <CopyToClipboard text={fullCurrentUrl} onCopy={() => copyLink()}>
              <PStyle className="cursor">
                {" "}
                <IconCopyLink className="fa fa-link" aria-hidden="true"/> Copy
                link
              </PStyle>
            </CopyToClipboard>
          </div>
        </div>
        <div
            className={classNames("dropdown", {
              show: isActiveToggle,
            })}
            onClick={toggleToggle}
        >
          <button type="button" className="btn-dropdown"></button>
          <div className="dropdown__menu">
            <a
                onClick={downloadFile}
                type="button"
                className="btn-download flex-center"
              id="tooltip-download"
            >
              {showLoadingDownload ? (
                <div className="loader"></div>
              ) : (
                <>
                  <i className="icon icon-download" aria-hidden="true"></i> Tải
                  xuống
                </>
              )}
            </a>{" "}
            <PStyles
              className="dropdown-item flex-lc justify-content-between"
              onClick={(e) => share()}
            >
              <FacebookShareButton
                url={fullCurrentUrl}
                quote={props.title}
                hashtag={"#hoc10.vn"}
                description={props.title}
                className="hoc10"
              >
                <FacebookIcon className="ml-2" size={24} round />{" "}
                <H1Style className="ml-1 ">Chia sẻ</H1Style>
              </FacebookShareButton>
            </PStyles>
          </div>
        </div>
      </div>
    </div>
  );
}

const IconCopyLink = styled.i`
  background: #bb0000;
  color: #fff;
  padding: 8px 8px;
  border-radius: 50px;
  margin-left: 22px;
`;
const PStyle = styled.p`
  margin-left: 6px;
  padding: 2px;
  &:hover {
    background: #e9ecef;
  }
`;
const PStyles = styled.p`
  padding: 12px 0;
`;
const H1Style = styled.h1`
  color: #fff;
  font-size: 16px;
`;
